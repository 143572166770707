import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, Observable, of, shareReplay, startWith, Subject, switchMap, take, tap, throwError } from 'rxjs';
import { Playlist } from 'src/app/@domain/playlist/entities/playlist.interface';
import { Video } from 'src/app/@domain/video/entities/video.interface';
import { Page } from '../../../@common/entities/page.interface';
import { ApiPath } from '../../../@common/http/api-path.enum';
import { Logger } from '../../../@common/log/logger';

@Injectable({
    providedIn: 'root',
})
export class PlaylistService {
    private refreshPlaylists$ = new Subject<void>();
    private allPlaylists$: Observable<Page<Playlist>>;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private logger: Logger
    ) {
        this.allPlaylists$ = this.refreshPlaylists$.pipe(
            startWith(null),
            switchMap(() => this.getPlaylists(9999, 0)),
            shareReplay(1)
        );
    }

    getAllPlaylists(): Observable<Page<Playlist>> {
        return this.allPlaylists$;
    }

    getPlaylists(limit: number, offset: number): Observable<Page<Playlist>> {
        return this.http.get<Page<Playlist>>(
            `${ ApiPath.playlist }/?limit=${ limit }&offset=${ offset }`,
            { withCredentials: true }
        ).pipe(
            shareReplay(1),
            catchError(e => {
                this.logger.apiError('Get playlists failed', e);
                return of({});
            })
        );
    }

    getPlaylist(id: string): Observable<Playlist> {
        return this.refreshPlaylists$.pipe(
            startWith(null),
            switchMap(() => this.http.get<Playlist>(`${ ApiPath.playlist }/${ id }/`, { withCredentials: true })),
            shareReplay(1),
            catchError(e => {
                this.logger.apiError('Get playlist failed', e);
                return throwError(() => e);
            })
        );
    }

    addPlaylist(title: string, user: number, thumbnail?: string, lectures?: Video[]): Observable<Playlist> {
        return this.http.post<Playlist>(
            `${ ApiPath.playlist }/`,
            { title, thumbnail, user, lectures },
            { withCredentials: true }
        ).pipe(
            take(1),
            tap(() => this.refreshPlaylists$.next()),
            catchError(e => {
                this.logger.apiError('Failed to add playlist', e)
                return throwError(() => e);
            }),
        );
    }

    editPlaylist(id: string, playlistData: any): Observable<Page<Playlist>> {
        return this.http.put<Page<Playlist>>(`${ ApiPath.playlist }/${ id }/`, playlistData, { withCredentials: true })
            .pipe(
                take(1),
                tap(() => this.refreshPlaylists$.next()),
                catchError(e => {
                    this.logger.apiError('Failed to edit playlist', e)
                    return throwError(() => e);
                }),
            );
    }

    deletePlaylist(id: string): Observable<Playlist> {
        return this.http.delete<Playlist>(`${ ApiPath.playlist }/${ id }/`, { withCredentials: true })
            .pipe(
                take(1),
                tap(() => this.refreshPlaylists$.next()),
                catchError(e => {
                    this.logger.apiError('Failed to delete playlist', e)
                    return throwError(() => e);
                }),
            );
    }

    addVideoToPlaylist(playlistId: number, lectureId: string) {
        this.http.put<{ message: string }>(
            `${ ApiPath.playlist }/${ playlistId }/add_lecture/${ lectureId }/`,
            null,
            { withCredentials: true }
        ).pipe(
            take(1),
            tap(() => this.refreshPlaylists$.next()),
            catchError(e => {
                this.logger.apiError('Failed to add video to playlist', e)
                return throwError(() => e);
            })
        ).subscribe(() => this.toastr.success('Lecture added to playlist'));
    }

    removeVideoFromPlaylist(playlistId: string, lectureId: number): Observable<string> {
        return this.http.put<{ message: string }>(
            `${ ApiPath.playlist }/${ playlistId }/remove_lecture/${ lectureId }/`,
            null,
            { withCredentials: true }
        ).pipe(
            take(1),
            map(({ message }) => message),
            tap(() => this.refreshPlaylists$.next()),
            catchError(e => {
                this.logger.apiError('Failed to remove video from playlist', e)
                return throwError(() => e);
            })
        );
    }

}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-badge',
    templateUrl: './badge.component.html',
    styleUrls: [ './badge.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class BadgeComponent implements OnInit {
  @Input({required: true}) text: string = '';

  ngOnInit(): void {
    this.text = this.text.charAt(0).toUpperCase() + this.text.slice(1);
  }
}

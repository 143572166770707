import { Pipe, PipeTransform } from '@angular/core';
import { DateUtil } from '../../../@common/util/date-util';


@Pipe({
    name: 'transformTimestamp',
    standalone: true,
})
export class TransformTimestampPipe implements PipeTransform {
    transform(duration: number | undefined, unit: 'ms' | 's' = 'ms'): string {
        return DateUtil.numberToTimestamp(duration, unit);
    }

}

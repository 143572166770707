export class DateUtil {

    public static stringToTimestamp(timestamp: string): number {
        const hhmmssPattern = /^(\d{2}):(\d{2}):(\d{2})$/;
        const mmssPattern = /^(\d{2}):(\d{2})$/;

        if (hhmmssPattern.test(timestamp)) {
            const match = timestamp.match(hhmmssPattern);
            const hours = parseInt(match![1], 10);
            const minutes = parseInt(match![2], 10);
            const seconds = parseInt(match![3], 10);

            return (hours * 3600 + minutes * 60 + seconds) * 1000;
        } else if (mmssPattern.test(timestamp)) {
            const match = timestamp.match(mmssPattern);
            const minutes = parseInt(match![1], 10);
            const seconds = parseInt(match![2], 10);

            return (minutes * 60 + seconds) * 1000;
        }

        throw new Error('Could not parse to slide time');
    }

    public static isValidTimestamp(timestamp: string): boolean {
        const hhmmssPattern = /^(\d{2}):(\d{2}):(\d{2})$/;
        const mmssPattern = /^(\d{2}):(\d{2})$/;

        if (hhmmssPattern.test(timestamp)) {
            const match = timestamp.match(hhmmssPattern);
            const hours = parseInt(match![1], 10);
            const minutes = parseInt(match![2], 10);
            const seconds = parseInt(match![3], 10);

            return hours < 24 && minutes < 60 && seconds < 60;
        }

        if (mmssPattern.test(timestamp)) {
            const match = timestamp.match(mmssPattern);
            const minutes = parseInt(match![1], 10);
            const seconds = parseInt(match![2], 10);
            return minutes < 60 && seconds < 60;
        }

        return false;
    }

    public static numberToTimestamp(milliseconds: number | undefined, unit: 'ms' | 's' = 'ms'): string {
        if (!milliseconds) {
            return '00:00';
        }

        const seconds = unit === 'ms' ? Math.floor(milliseconds / 1000) : milliseconds;
        const hrs = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = Math.floor(seconds % 60);

        let time = '';

        if (hrs) {
            time += hrs > 9 ? `${ hrs }:` : `0${ hrs }:`;
        }

        time += mins > 9 ? `${ mins }:` : `0${ mins }:`;
        time += secs > 9 ? `${ secs }` : `0${ secs }`;

        return time;
    }

}

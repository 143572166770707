import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, inject, input, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faBookmark as faBookmarkSolid, faLock, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { ProgressBarModule } from 'primeng/progressbar';
import { TooltipModule } from 'primeng/tooltip';
import { Observable, of } from 'rxjs';
import { BookmarkService } from 'src/app/@domain/bookmark/application/bookmark.service';
import { ItemsViewService } from 'src/app/@domain/items-view/items-view.service';
import { PlaylistService } from 'src/app/@domain/playlist/application/playlist.service';
import { ImageDirective } from '../../../../../@common/DOM/image.directive';
import { UserService } from '../../../../../@domain/user/application/user.service';
import { VideoUploadService } from '../../../../../@domain/video/application/video-upload.service';
import { VideoPart } from '../../../../../@domain/video/entities/video-part.interface';
import { Video, } from '../../../../../@domain/video/entities/video.interface';
import { BadgeComponent } from '../../../../global/badge/badge.component';
import { TransformTimestampPipe } from '../../../../global/pipes/transform-timestamp.pipe';
import { TimeBadgeComponent } from '../../../../global/time-badge/time-badge.component';

@Component({
    selector: 'app-video-item',
    templateUrl: './video-item.component.html',
    styleUrls: [ './video-item.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ NgIf, RouterLink, ImageDirective, TimeBadgeComponent, NgFor, BadgeComponent, FaIconComponent, NgClass, ConfirmationPopoverModule, AsyncPipe, TransformTimestampPipe, ProgressBarModule, TooltipModule ]
})
export class VideoItemComponent implements OnInit {
    private itemsViewService = inject(ItemsViewService);
    private bookmarksService = inject(BookmarkService);
    private playlistService = inject(PlaylistService);
    private userService = inject(UserService);
    private videoUploadService = inject(VideoUploadService);

    @HostBinding('hidden') wasRemoved: boolean = false;
    lecture = input.required<Video>();
    part = input<VideoPart | null>(null);
    gridView = input(false);
    listView = input(false);
    isPlaylist = input(false);
    isBookmark = input(false);
    playlistId = input('');
    canDelete = input(false);
    isGridView$!: Observable<boolean>;
    user = this.userService.userProfile;
    customLectureTitle: string = '';
    minutesLeft!: number;
    progress!: number;
    faBookmarkSolid = faBookmarkSolid;
    faTrash = faTrash;
    faLock = faLock;

    public ngOnInit(): void {
        //remove this
        this.customLectureTitle = this.cutTitle();
        this.isGridView$ = this.setupIsGridView();

        // const { duration = 0, left_off = 0 } = this.lecturePart();
        // TODO: fix this
        const { duration = 0, left_off = 0 } = this.lecture();
        this.minutesLeft = Math.round((duration - left_off) / 60);
        this.progress = Math.round((left_off * 100) / duration);
    }

    private setupIsGridView(): Observable<boolean> {
        if (this.gridView()) {
            return of(true);
        }

        if (this.listView() || this.isPlaylist() || this.isBookmark()) {
            return of(false);
        }

        return this.itemsViewService.onChangeView();
    }

    public cutTitle() {
        if (this.lecture().title.split('').length <= 80) {
            return this.lecture().title;
        }

        return this.lecture().title.slice(0, 80) + '...';
    }

    public removeFromBookmarksOrPlaylist(): void {
        if (this.isBookmark()) {
            this.bookmarksService.unbookmarkLecture(this.lecture().id)
                .subscribe(() => this.wasRemoved = true);
        }

        if (this.isPlaylist()) {
            this.playlistService.removeVideoFromPlaylist(this.playlistId(), this.lecture().id)
                .subscribe(() => this.wasRemoved = true);
        }
    }

    public onDeleteVideo(event: Event): void {
        event.preventDefault();
        event.stopPropagation();

        if (confirm('Are you sure you want to delete the lecture?')) {
            this.videoUploadService.deleteVideo(this.lecture().slug).subscribe();
        }
    }
}

import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { catchError, Observable, of, take, throwError } from 'rxjs';
import { ApiPath } from '../../../@common/http/api-path.enum';
import { RequestHeader } from '../../../@common/http/request-header';
import { Logger } from '../../../@common/log/logger';
import { LectureData } from '../../upload/entities/lecture-data.interface';
import { UploadUtil } from '../../upload/entities/upload-util';
import { VideoDto } from '../entities/video.dto';
import { Video } from '../entities/video.interface';

@Injectable({
    providedIn: 'root',
})
export class VideoUploadService {
    private http = inject(HttpClient);
    private logger = inject(Logger);
    private toastr = inject(ToastrService);

    public createVideo(data: LectureData): Observable<any> {
        return this.http.post<any>(ApiPath.lectures + '/', this.toLectureDto(data), { withCredentials: true })
            .pipe(
                take(1),
                catchError(e => {
                    this.logger.apiError('Post lecture failed', e);
                    return throwError(() => e);
                })
            );
    }

    public updateVideo(slug: string, data: LectureData) {
        return this.http.patch<any>(`${ ApiPath.lectures }/${ slug }/`, this.toLectureDto(data), { withCredentials: true })
            .pipe(
                take(1),
                catchError(e => {
                    this.logger.apiError('Update lecture failed', e);
                    this.toastr.error('Update lecture failed', 'Error')
                    return throwError(() => e);
                })
            );
    }

    public deleteVideo(slug: string | undefined): Observable<unknown> {
        if (!slug) {
            this.logger.clientError('Delete lecture failed, slug was not provided', null);
            return of(null);
        }

        return this.http.delete<Video>(`${ ApiPath.lectures }/${ slug }/`, { withCredentials: true })
            .pipe(
                take(1),
                catchError(e => {
                    this.logger.apiError('Delete lecture failed', e);
                    return throwError(() => e);
                })
            );
    }

    public uploadVideoToMinio(url: string, file: File): Observable<any> {
        let video = new FormData();
        video.append('video', file);

        return this.http.put<any>(url, file, {
            headers: RequestHeader.contentTypeAudio(UploadUtil.getFileExtension(file)),
            withCredentials: true,
            reportProgress: true,
            observe: 'events',
        }).pipe(
            catchError(e => {
                this.logger.apiError('Upload video to Minio failed', e);
                return throwError(() => e);
            })
        );
    }

    private toLectureDto(data: LectureData): VideoDto {
        return {
            public: data.public,
            date: data.date,
            title: data.title!,
            slug: data.slug!,
            description: data.description!,
            type: data.type!,
            categories: data.categoryIds?.map(id => +id),
            authors: data.authorIds?.map(id => +id),
            language: data.languageId!,
            videos: data.videos,
            duration: data.duration!,
            event: +data.eventId!,
            thumbnail: data.thumbnail!,
            draft: data.draft
        }
    }

}

@if (isGridView$ | async) {
    <div class="grid-view" pTooltip="Video is private" [tooltipDisabled]="!!lecture().public">
        <a class="card" routerLink="/{{lecture().slug || 'error-page'}}/">
            <div class="card-header">
                @if (!lecture().public) {
                    <div class="private-badge">
                        <fa-icon [icon]="faLock" />
                        Private
                    </div>
                }
                <img [src]="part()?.thumbnail || lecture().thumbnail || './assets/download.png'" alt="video-img"
                     loading="lazy" />
                @if (part()?.duration || lecture().duration; as duration) {
                    <app-time-badge [text]="duration | transformTimestamp: 's'" />
                }
            </div>
            <div class="card-body lectures">
                <h4>{{ customLectureTitle }}</h4>
                <div class="py-1 pb-1">
                    @if (lecture().authors?.length) {
                        @for (author of lecture().authors; track author.id) {
                            <p style="font-weight: 600" class="pb-1">{{ author.name }}</p>
                        }
                    }

                    <div class="d-flex" style="justify-content: space-between; margin-top: 0.25rem">
                        <p>{{ lecture().published }}</p>
                        <p>&nbsp;·&nbsp;</p>
                        <p>{{ lecture().views }} Views</p>
                    </div>
                </div>
                <div *ngIf="lecture().type || canDelete()" class="d-flex flex-wrap badge" [class.delete]="canDelete()">
                    <app-badge [text]="lecture().type ?? ''" />
                    <fa-icon *ngIf="canDelete()" class="delete-icon" [icon]="faTrash" (click)="onDeleteVideo($event)" />
                </div>
            </div>
            <div class="progress-bar" *ngIf="progress">
                <p>{{ minutesLeft ? minutesLeft + " min left" : " " }}</p>
                <p-progressBar [style]="{ height: '4px'}" color="#FF2E59" [value]="progress" [showValue]="false" />
            </div>
        </a>
    </div>
} @else {
    <div [ngClass]="(isPlaylist() || isBookmark()) ? 'list-view-playlist' : 'video-list-view'">
        <div class="d-flex icon" *ngIf="isPlaylist() || isBookmark()">
            <!--    <app-playlist-menu-dropdown
              style="cursor: pointer"
              (onAction)="onAction($event)"
              *ngIf="isPlaylist"
            ></app-playlist-menu-dropdown> -->

            <fa-icon
                    mwlConfirmationPopover
                    popoverTitle="Delete confirmation"
                    popoverMessage="Are you sure you want to remove the lecture from bookmarks?"
                    placement="right"
                    (confirm)="removeFromBookmarksOrPlaylist()"
                    *ngIf="isBookmark()"
                    [icon]="faBookmarkSolid"
            ></fa-icon>
            <fa-icon
                    mwlConfirmationPopover
                    popoverTitle="Delete confirmation"
                    popoverMessage="Are you sure you want to remove the lecture from playlist?"
                    placement="right"
                    (confirm)="removeFromBookmarksOrPlaylist()"
                    *ngIf="isPlaylist()"
                    [icon]="faTrash"
            />
        </div>
        <a routerLink="/{{lecture().slug || 'error-page'}}/">
            <img [src]="part()?.thumbnail || lecture().thumbnail ||'./assets/download.png'" alt="video-img"
                 loading="lazy" />
        </a>
        <a class="right" routerLink="/{{lecture().slug || 'error-page'}}/">
            <div class="info">
                <strong class="title">{{ part()?.title || lecture().title }}</strong>
                <div *ngIf="lecture().authors?.length">
                    @if (isBookmark()) {
                        <div class="d-flex flex-wrap">
                            <p *ngFor="let author of lecture().authors; let isLast = last" class="pb-1">
                                {{ author.name }}<span *ngIf="!isLast">, &nbsp;</span>
                            </p>
                        </div>
                    } @else {
                        @for (author of lecture().authors; track author.id) {
                            <p *ngIf="author.name" style="font-weight: 600" class="pb-1">{{ author.name }}</p>
                        }
                    }
                </div>
            </div>
            <div class="numbers">
                @if (lecture().published) {
                    <p>{{ lecture().published }}</p>
                }
                <p>{{ lecture().views }} Views</p>
                @if ((isPlaylist() || isBookmark()) && lecture().type; as type) {
                    <app-badge [text]="type" />
                }
            </div>
        </a>
    </div>
}

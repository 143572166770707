import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-time-badge',
    templateUrl: './time-badge.component.html',
    styleUrls: [ './time-badge.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class TimeBadgeComponent {
  @Input({required: true}) text: string = '';
}

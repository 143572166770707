import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, Observable, shareReplay, take } from 'rxjs';
import { Logger } from 'src/app/@common/log/logger';
import { Page } from '../../../@common/entities/page.interface';
import { ApiPath } from '../../../@common/http/api-path.enum';
import { Video } from '../../video/entities/video.interface';

@Injectable({
    providedIn: 'root',
})
export class BookmarkService {
    private http = inject(HttpClient);
    private logger = inject(Logger);

    getBookmarkedLectures(limit: number, offset: number): Observable<Page<Video>> {
        return this.http.get<Page<Video>>(
            `${ ApiPath.bookmarks }/?limit=${ limit }&offset=${ offset }`,
            { withCredentials: true }
        ).pipe(
            shareReplay(1),
            catchError(e => {
                this.logger.apiError('Get bookmarked lectures failed', e);
                throw e;
            })
        );
    }

    bookmarkLecture(lectureId: number): Observable<{ bookmarked: boolean }> {
        return this.http.post<{ bookmarked: boolean }>(
            `${ ApiPath.bookmarksAdd }/${ lectureId }/`,
            null,
            { withCredentials: true }
        ).pipe(
            take(1),
            catchError(e => {
                this.logger.apiError('Bookmark lecture failed', e);
                throw e;
            })
        );
    }

    unbookmarkLecture(lectureId: number): Observable<{ bookmarked: boolean }> {
        return this.http.delete<{ bookmarked: boolean }>(
            `${ ApiPath.bookmarksRemove }/${ lectureId }/`,
            { withCredentials: true }
        ).pipe(
            take(1),
            catchError(e => {
                this.logger.apiError('Unbookmark lecture failed', e);
                throw e;
            })
        );
    }

}

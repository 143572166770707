import { HttpHeaders } from "@angular/common/http";

export class RequestHeader {

  public static contentTypeAudio(ext: string): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': `audio/${ext}`,
    });
  }

  public static contentTypeImage(ext: string): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': `image/${ext}`,
    });
  }

}
